const itemMutations = {
  setBookChart(state, payload) {
    state.bookChart = payload;
  },
  addDetailItems(state, payload) {
    state.detailItems.push(payload);
  },
  setSnackbar(state, snackbar) {
    state.snackbar = snackbar;
  },
  setSnackbarAddCart(state, snackbar) {
    state.snackbarAddCart = snackbar;
  }
}

const itemActions = {

}
const itemGetters = {
  getAncillaryText: state => {
    // 4つの付帯テキストをまとめて返す
    return state.detailItems[0].ancillaryText.length ? state.detailItems[0].ancillaryText : [];
  },
  getAudioFileUrl: state => state.detailItems[0].audioUrl,
  getAuthor: state => state.detailItems.length ? state.detailItems[0].author : null,
  getAuthors: state => state.detailItems.map(item => item.author),
  getAuthorList: state => state.detailItems.length ? state.detailItems[0].authors : null,
  getBasePriceInTax: state => state.detailItems.length ? Number(state.detailItems[0].base_price_in_tax).toLocaleString() : null,
  getBookAge: state => state.detailItems.length ? state.detailItems[0].bookAge : null,
  getBookChart: state => state.bookChart,
  getChildItemIds: state => state.detailItems[0].childItem,
  getChildItemSetIds: state => state.detailItems[0].childItemSet,
  getDescription: state => {
    return state.detailItems[0].description;
  },
  getVbDescription: state => {
    return state.detailItems[0].vbDescription;
  },
  getImageUrl: (state) => {
    return state.detailItems[0].image_url.length ? state.detailItems[0].image_url : null;
  },
  getImageUrlFirst: (state) => {
    return state.detailItems[0].image_url.length ? state.detailItems[0].image_url[0] : null;
  },
  getItem: state => {
    let item = state.detailItems[0];
    if (item.hasOwnProperty('imageUrl')) {
      item.image_url = item.imageUrl
    }
    return item
  },
  getItemInfoForDetailModal: (state, getters) => {
    let arr = {};
    const item = state.detailItems[0];
    (item.cCodes && item.cCodes.c3_4) && (arr["ジャンル"] = item.cCodes.c3_4.name);
    item.publisher && (arr["出版社"] = item.publisher);
    item.publishDate && (arr["出版日"] = item.publishDate);
    let isbn = getters.getIsbn10orAsin;
    if (isbn && isbn.slice(0, 1) !== "B") {
      arr["ISBN-10"] = getters.getIsbn10orAsin;
    }
    getters.getIsbn13OrExchange && (arr["ISBN-13"] = getters.getIsbn13OrExchange);
    item.language && (arr["言語"] = item.language === "jpn" ? "日本語" : null);
    item.pageNumber && (arr["本の長さ"] = item.pageNumber + 'ページ');
    item.format && (arr["版型"] = item.format);
    return arr;
  },
  getItemTag: state => {
    if (state.detailItems[0].itemTag) {
      return state.detailItems[0].itemTag;
    }
  },
  getIsbn10orAsin: state => {
    return state.detailItems[0].isbnCode10 ? state.detailItems[0].isbnCode10 : state.detailItems[0].asinCode;
  },
  getIsbn13OrExchange: (state, getters) => {
    return state.detailItems[0].isbnCode13 ? state.detailItems[0].isbnCode13 : ISBNUtils.isbn10to13(getters.getIsbn10orAsin);
  },
  getLastVolumeNumber: state => {
    return state.detailItems[0].lastVolumeNumber
  },
  getPublisher: state => state.detailItems.length ? "出版社：" + state.detailItems[0].publisher : null,
  getPublishers: state => state.detailItems.map(item => item.publisher ? item.publisher : null),
  getPublishDate: state => state.detailItems.length ? state.detailItems[0].publishDate : null,
  getSearchItems: (state) => {
    return state.searchItemlist;
  },
  getSelectedCondition: state => state.selectedConditionCode,
  getStockEveryCondition: (state) => {
    const list = []
    state.detailItems[0].genpinList.forEach(genpin => {
      list.push({
        condition_code: genpin.conditionCode,
        radio_name: "condition",
        condition_text: genpin.conditionName,
        stock: genpin.stock,
        isStock: genpin.stock !== 0,
      })
    })
    return list
  },
  getSubTitle: state => state.detailItems.length ? state.detailItems[0].subtitle : null,
  getTitle: (state) => {
    return state.detailItems.length ? state.detailItems[0].title : null;
  },
  getTitles: (state) => {
    return state.detailItems.map(item => item.title);
  },
  getVsCatalogId: state => state.detailItems.length ? state.detailItems[0].vs_catalog_id : null,
  getVsCatalogIds: state => state.detailItems.map(item => item.vs_catalog_id),
  isCompleted: state => state.detailItems[0].isComplete,
  isOriginal: state => state.detailItems[0].isOriginal,
  isParentItemSet: state => !!state.detailItems[0].isParentItemSet,
  getSnackbar: state => state.snackbar,
  getSnackbarAddCart: state => state.snackbarAddCart,
}

const moduleItem = {
  namespaced: true,
  state: {
    detailItems: [],
    selectedConditionCode: "",
    bookChart: {},
    snackbar: {
      color: null,
      icon: null,
      text: null,
      timeout: 2000,
      visible: false,
      position: null,
      mode: null,
    },
    snackbarAddCart: {
      color: null,
      icon: null,
      text: null,
      timeout: 2000,
      visible: false,
      position: null,
      mode: null,
      itemTitle: null,
      itemPrice: null,
      itemImageUrl: null,
      itemConditionName: null,
    },
  },
  mutations: itemMutations,
  actions: itemActions,
  getters: itemGetters,
}
export {moduleItem};