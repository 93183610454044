import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const moduleFavorite = {
    namespaced: true,
    state: {
        favoriteVsCatalogIDs: [],
        isRequesting: false,
        snackbar: {},
        postQueue: [],
        deleteQueue: [],
        loginModal: {active: false, is_stop_event: false}
    },
    getters: {
        containedInFavoriteStore: (state) => (vsCatalogID) => {
            return state.favoriteVsCatalogIDs.includes(vsCatalogID);
        },
        getIsRequesting: (state) => {
            return state.isRequesting;
        },
        getSnackbar: (state) => {
            return state.snackbar;
        },
        getPostQueue: (state) => {
            return state.postQueue;
        },
        getDeleteQueue: (state) => {
            return state.deleteQueue;
        },
        getStateToLoginModal: (state) => {
            return state.loginModal;
        }
    },
    actions: {
        pushToFavoriteStore({commit}, vsCatalogID) {
            commit('push', vsCatalogID);
        },
        removeFromFavoriteStore({commit}, vsCatalogID) {
            commit('remove', vsCatalogID);
        },
        createRequestConfig({ commit, state }, payload) {
            const isFavorite = payload.isFavorite;
            const vsCatalogID = payload.vsCatalogID;

            const existingPostIndex = state.postQueue.findIndex(
              (config) => config.vsCatalogID === vsCatalogID
            );

            const existingDeleteIndex = state.deleteQueue.findIndex(
              (config) => config.vsCatalogID === vsCatalogID
            );

            if (isFavorite) {
                // 新しいPOSTリクエストをpostQueueに追加
                if (existingPostIndex === -1) {
                    commit('setPostQueue', [...state.postQueue, { vsCatalogID }]);
                }

                // 競合するDELETEリクエストをdeleteQueueから削除
                if (existingDeleteIndex !== -1) {
                    const updatedQueue = state.deleteQueue.filter(
                      (config, index) => index !== existingDeleteIndex
                    );
                    commit('setDeleteQueue', updatedQueue);
                }
            } else {
                // 新しいDELETEリクエストをdeleteQueueに追加
                if (existingDeleteIndex === -1) {
                    commit('setDeleteQueue', [...state.deleteQueue, { vsCatalogID }]);
                }

                // 競合するPOSTリクエストをpostQueueから削除
                if (existingPostIndex !== -1) {
                    const updatedQueue = state.postQueue.filter(
                      (config, index) => index !== existingPostIndex
                    );
                    commit('setPostQueue', updatedQueue);
                }
            }
        },
        async processRequestQueue({state, commit, dispatch}) {
            if (state.postQueue.length > 0 || state.deleteQueue.length > 0) {

                const postVsCatalogIDs = state.postQueue.map(request => request.vsCatalogID);
                const deleteVsCatalogIDs = state.deleteQueue.map(request => request.vsCatalogID);

                // 投稿キューと削除キュー間の重複を削除する
                const commonVsCatalogIDs = postVsCatalogIDs.filter(id => deleteVsCatalogIDs.includes(id));
                commonVsCatalogIDs.forEach(vsCatalogID => {
                    const postIndex = state.postQueue.findIndex(request => request.vsCatalogID === vsCatalogID);
                    const deleteIndex = state.deleteQueue.findIndex(request => request.vsCatalogID === vsCatalogID);

                    if (postIndex !== -1) {
                        commit('setPostQueue', [...state.postQueue.slice(0, postIndex), ...state.postQueue.slice(postIndex + 1)]);
                    }

                    if (deleteIndex !== -1) {
                        commit('setDeleteQueue', [...state.deleteQueue.slice(0, deleteIndex), ...state.deleteQueue.slice(deleteIndex + 1)]);
                    }
                });

                if (postVsCatalogIDs.length > 0) {
                    await dispatch('processRequests', {vsCatalogIDs: postVsCatalogIDs, requestType: 'POST'});
                }

                if (deleteVsCatalogIDs.length > 0) {
                    await dispatch('processRequests', {vsCatalogIDs: deleteVsCatalogIDs, requestType: 'DELETE'});
                }
            }
        },
        async processRequests({commit, dispatch, getters}, payload) {

            const vsCatalogIDs = payload.vsCatalogIDs;
            const requestType = payload.requestType;
            // リクエスト中フラグを設定
            commit('setIsRequesting', true);
            const isFavorite = requestType === 'POST';

            axios({
                method: requestType,
                url: '/api/library/folder/favorite/items',
                data: {"vsCatalogIDs": vsCatalogIDs}
            }).then(response => {
                if (!response || response.status !== 200) {
                    return;
                }

                // リクエストキューを再起的にクリア
                vsCatalogIDs.forEach(requestVsCatalogID => {
                    if (isFavorite) {
                        dispatch('pushToFavoriteStore', requestVsCatalogID);
                        commit('removePostQueue', requestVsCatalogID);
                    } else {
                        dispatch('removeFromFavoriteStore', requestVsCatalogID);
                        commit('removeDeleteQueue', requestVsCatalogID);
                    }
                });
                // リクエスト中フラグをリセット
                commit('setIsRequesting', false);

                const text = isFavorite ? 'お気に入りに追加しました' : 'お気に入りから削除しました';
                commit('setSnackbar', {
                    vsCatalogID: vsCatalogIDs[0],
                    color: "#009098",
                    icon: "mdi-check",
                    position: "right",
                    text: text + "<a href='/shelf-items/list'><span class='font-weight-bold white--text pl-3'>確認する</span></a>",
                    timeout: 5000,
                    visible: true
                });
            }).catch(error => {
                commit('setSnackbar', {
                    vsCatalogID: vsCatalogIDs[0],
                    color: "#980000",
                    icon: "mdi-check",
                    position: "right",
                    text: isFavorite ? "お気に入りに追加できません" : "お気に入りから削除できません",
                    timeout: 1000,
                    visible: true
                });
            });
        },
    },
    mutations: {
        push(state, id) {
            state.favoriteVsCatalogIDs.push(id);
        },
        remove(state, id) {
            state.favoriteVsCatalogIDs = state.favoriteVsCatalogIDs.filter(_id => id !== _id);
        },
        setIsRequesting(state, isRequesting) {
            state.isRequesting = isRequesting;
        },
        setSnackbar(state, snackbar) {
            state.snackbar = snackbar;
        },
        setPostQueue(state, queue) {
            state.postQueue = queue;
        },
        setDeleteQueue(state, queue) {
            state.deleteQueue = queue;
        },
        removePostQueue(state, id) {
            state.postQueue = state.postQueue.filter(request => id !== request.vsCatalogID);
        },
        removeDeleteQueue(state, id) {
            state.deleteQueue = state.deleteQueue.filter(request => id !== request.vsCatalogID);
        },
        setStateToLoginModal(state, value) {
            state.loginModal = value;
        },
    },
}

export {moduleFavorite};